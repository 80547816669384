import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout';

const Page = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  const page = data.allContentfulPage.edges[0].node;

  return (
    <Layout>
      <div className="post">
        <Helmet title={`${page.title} | ${siteTitle}`} />
        <h2 className="title">{page.title}</h2>
        <div className="entry" dangerouslySetInnerHTML={{ __html: page.content.childMarkdownRemark.html }}></div>
      </div>
    </Layout>
  );
}

export default Page

export const pageDetailFragment = graphql`
  fragment pageDetail on ContentfulPage {
    id
    title
    content {
      childMarkdownRemark {
        html
      }
    }
    fields {
      path
    }
  }
`;

export const pageQuery = graphql`
  query PageQuery($id: String!) {
    site {
      ...siteMeta
    }
    allContentfulPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          ...pageDetail
        }
      }
    }
  }`
